<script setup>
import { useInAppView } from '@@/composables/useInAppView';
import { useDefaultTrial } from '@@/composables/useDefaultTrial';
import { useMetaStore } from '@@/stores/Meta';
import { useUserStore } from '@@/stores/User';

defineProps({
  benefitsText: {
    type: String,
    default: 'Upgrade to All-Access and receive exclusive benefits:',
  },
  headerText: {
    type: String,
    default: 'Want to see more?',
  },
  showBenefits: {
    type: Boolean,
    default: true,
  },
  smallPadding: {
    type: Boolean,
    default: false,
  },
});

const metaStore = useMetaStore();
const userStore = useUserStore();

const { registerButtonText } = useDefaultTrial();
const { inAppView } = useInAppView();
const isGuest = computed(() => userStore.isGuest);

const returnTo = computed(() => {
  const { fullPath } = useRoute();
  return fullPath;
});

const source = computed(() => {
  const { name } = useRoute();
  return name ? String(name).replace('-slug', '') : 'unknown-source';
});

const upgradeReasons = computed(() => metaStore.pricing?.upgrade_reasons || []);
</script>

<template>
  <UpgradeToAllAccessCard
    :benefits-text="benefitsText"
    :header-text="headerText"
    :is-guest="isGuest"
    :in-app-view="inAppView"
    :register-button-text="registerButtonText"
    :return-to="returnTo"
    :show-benefits="showBenefits"
    :source="source"
    :small-padding="smallPadding"
    :upgrade-reasons="upgradeReasons"
  />
</template>
